
  import hightlightMixin from '../../plugins/hightlightMixin.js';
  import { mapGetters } from 'vuex';
  import dataLayerMixin from '../filters/data.layer.list.mixin';
  import Skeleton from '@/components/skeleton';
  import productDiscountMixin from '@mtntop/utils/src/mixins/productDiscountMixin';

  export default {
    name: 'ProductCardInfo',
    components: {
      Skeleton,
      ProductInventory: () => import('../productView/ProductInventory/ProductCheckInventory.vue'),
      ProductCardButtonsList: () => import('./ProductCardButtonsList.vue'),
      ProductView: () => import('../productView/ProductView1.vue'),
      MImg: () => import('../MPowerImg'),
    },
    mixins: [productDiscountMixin, hightlightMixin, dataLayerMixin],
    data() {
      return {
        productData: null,
        productViewModalProps: null,
        loadingProductViewModal: false,
      };
    },
    props: {
      classNames: {
        type: Object,
        default: () => ({
          cardClass: '',
          imgContainerClass: '',
          priceClass: '',
          nameClass: '',
          imgClass: '',
          priceRangeClass: '',
          bodyClass: '',
          imgAltModifier: '',
        }),
      },
      productComponentProps: {
        type: Object,
        default: () => ({
          openProductInModal: false,
          productButton: {
            showDesign: false,
            showCustomize: false,
            showAddToCart: false,
            showProofRequest: false,
            showQuoteRequest: false,
            showAddToBox: false,
          },
          pricing: {
            showDecorated: false,
            showSample: false,
            showBlank: false,
            showBox: false,
          },
        }),
      },
      product: {
        //
        type: Object,
        required: true,
      },
      checkingPrices: {
        type: Boolean,
      },
      hideParts: {
        type: Boolean,
      },
      imgSrc: {
        type: String,
      },
      headingText: {
        type: String,
      },
      headingHtml: {
        type: String,
      },
      bodyText: {
        type: String,
      },
      bodyHtml: {
        type: String,
      },
      hasFavorite: {
        type: Boolean,
        default: false,
      },
      hideColors: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters({
        customer: 'auth/loggedInUser',
        customerFavorites: 'favorites/customerFavorites',
        storeInformation: 'storeInformation',
        storeCurrencySign: 'storeCurrencySign',
        loggedInModal: 'favorites/loggedInModal',
      }),
      isCategoriesList() {
        return this.$route.fullPath.includes('cat/');
      },
      productViewModalPricing() {
        const pricing = this.productComponentProps.pricing || {};
        const possiblePricingTabs = ['Decorated', 'Blank', 'Sample', 'Box'];
        return Object.keys(pricing)
          .filter((key) => pricing[key])
          .map((i) => i.replace('show', ''))
          .filter((i) => possiblePricingTabs.includes(i));
      },
      productPriceMin() {
        return this.storeProductFundraisingAmountMain(this.product.priceMin * this.storeInformation.currencyRate);
      },
      isFavorite() {
        return this.customerFavorites.includes(this.product.slug);
      },
      productImage() {
        return (
          this.product.imgSrc ||
          this.product.imgUrlSmall ||
          this.product.imgUrl ||
          (this.product.imageDefault ? this.product.imageDefault.urlSmall || this.product.imageDefault.url : '')
        );
      },
    },
    methods: {
      checkURL(url) {
        const matched = url.match(/\.(jpeg|jpg|gif|png)$/);
        const type = matched && matched['1'];
        return type === 'jpg' ? 'jpeg' : type;
      },
      async navigateToProducts(e) {
        e.preventDefault();
        e.stopPropagation();

        try {
          const dataLayer = window.dataLayer;
          dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
          dataLayer.push({
            event: 'select_item',
            ecommerce: {
              items: [this.productItemDataLayer(this.product, 0)],
            },
          });
        } catch (e) {}
        if (this.productComponentProps.openProductInModal && this.isCategoriesList) {
          try {
            this.loadingProductViewModal = true;
            this.productData = await this.$api.products.getProductBySlug(this.product.slug, {});
            const { storeUrl } = this.storeInformation;
            const { pageData } = await this.$api.stores.getStorePageDetails(
              {
                storeUrl,
                urlSlug: 'product',
                pageDataReq: this.$route.query,
                pageHeadTagReq: {
                  additionalSlug: this.$route.params.slug,
                  query: {},
                  canonicalPath: 'products',
                },
              },
              this.$axios
            );
            this.productViewModalProps = pageData.find((i) => i.name.includes('ProductView')).props;
            return this.$refs.productViewModal.show();
          } catch (e) {
            console.log(e);
          } finally {
            this.loadingProductViewModal = false;
          }
        }
        this.$router.push(`/products/${this.product.slug}`);
      },
      async toggleFavorite(e) {
        e.preventDefault();
        if (!this.customer && !this.loggedInModal.productsAdded) {
          this.showFavoritesModal();
          this.$store.commit('favorites/PRODUCTS_ADDED');
        }
        await this.$store.dispatch('favorites/toggleFavorite', this.product);
      },
    },
  };
